import React from 'react';
import Header2 from '../Common/Header2';
import Footer from '../Common/Footer';
import Banner from './../Segments/Banner';
import About2 from './../Segments/About2';
import TeamMembers2 from './../Segments/TeamMembers2';
import Services from './../Segments/Services';
import Story from './../Segments/Story';
import MaisonLuxe from './../../images/gallery/portrait/luxury-home.jpg';
import ite8 from '../../images/ite/ite8.jpg';
import ite9 from '../../images/ite/ite9.jpg';
import OwlCarousel from 'react-owl-carousel';

const images = [
    require('./../../images/ite/ite1.jpg'),
    require('./../../images/ite/ite2.jpg'),
    require('./../../images/ite/ite3.jpg'),
    require('./../../images/ite/ite4.jpg'),
    require('./../../images/ite/ite5.jpg'),
    require('./../../images/ite/ite6.jpg'),
    require('./../../images/ite/ite7.jpg'),

    
]

var bnrimg = require('./../../images/banner/banAbout.jpg');

class Ite extends React.Component {
    render() {
        const options = {
            loop: true,
            margin: 0,
            autoplay: true,
            autoplayTimeout: 3000,
            //center: true,
            nav: false,
            dots: true,
            navText: ['<i class="fa fa-angle-left"></i>', '<i class="fa fa-angle-right"></i>'],
            responsive: {
                0: {
                    items: 1
                },
                480: {
                    items: 1
                },
                767: {
                    items: 1
                },
                1000: {
                    items: 1
                }
            }
        };
        return (
            <>
                <Header2 />
                <div className="page-content">
                    <Banner title="Isolation Thermique par l'Extérieur." pagename="ITE" bgimage={MaisonLuxe}/>
                    <>
                <div className="section-full p-tb90 bg-gray square_shape2 tm-about2-wrap">
                        <div className="container">
                            <div className="section-content ">
                                <div className="row">
                                    <div className="col-lg-5 col-md-6">
                                    <img src={ite8} alt='' style={{width: '100%', height: "400px", objectFit: "cover"}} />
                                    </div>
                                    <div className="col-lg-6 col-md-6 ">
                                        <div className="m-about-containt text-black p-t30">
                                            <span  className="font-30 font-weight-300 text-uppercase">Isolation Thermique par l'Exterieur.</span>
                                            <h2 className="font-40 text-uppercase">Qu'est ce que c'est ?</h2>
                                            <p><b>La mauvaise isolation thermique est la première cause d'insatisfaction des français vis à vis de leur logement. </b></p>
                                            <p className='text-justify'>L’isolation par l’extérieur de votre maison a pour objectif d’améliorer le confort thermique en ajoutant des isolants sur les parois de l’habitat en contact avec l’extérieur. 
                                            Cette technique se révèle très efficace thermiquement et préserve votre surface habitable. La performance des parois extérieures est la cause de 20% à 25% des déperditions thermiques en hiver, cause d'une augmentation
                                            de la consommation électrique afin de compenser la perte de chaleur. En été, une bonne isolation permet de garder l'habitatioin fraiche plus longtemps.
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="container">
                            <div className="section-content ">
                                <div className="row">
                                    <div className="col-lg-6 col-md-6 ">
                                        <div className="m-about-containt text-black p-t30">
                                            <span  className="font-30 font-weight-300 text-uppercase">Comment isole-t-on ?</span>
                                            <h2 className="font-40 text-uppercase">Les trois techniques.</h2>
                                            <p><b>Selon le matériau de construction : isolation enduite, avec un bardage, ou mise en place d’un double mur. Ces différentes techniques induisent des rendus esthétiques différents :</b></p>
                                            <p className='text-justify'>
                                            <li>Solution n°1 : L'enduit sur isolant PSE (polystyrène expansé)</li>
                                            <li>Solution n°2 : L'isolation sous bardage (ou véture) ventilé (avec lame d'air sur ossature rapportée)</li>
                                            <li>Solution n°3 : L'enduit à la chaux sur isolant laine de bois.</li>
                                            <br/>
                                            <p>Jusqu'à 30 % d'économie d'énergie avec l'isolation par l'extérieur. En isolant votre maison par l'extérieur, vous pourrez faire jusqu'à 30 % d'économie sur votre facture de chauffage. De plus les systèmes d'isolation thermique par l'extérieur sont éligibles aux crédits d'impôts (CITE) et à l'éco-prêt à taux zéro.</p>
                                            <li>MaPrimeRénov’, la prime de l’Anah couvre jusqu’à 75 € / m² pour vos travaux d’isolation par l’extérieur ;</li>
                                            <li>l’éco-prêt à taux zéro vous permet d’emprunter jusqu’à 15 000 € pour une isolation des murs par l’extérieur, remboursables sur une durée de 20 ans ;</li>
                                            <li>la TVA réduite à 5,5% est appliquée directement sur votre facture par votre artisan RGE pour les matériaux d’isolation et leur pose.</li>
                                            </p>
                                        </div>
                                    </div>
                                    <div className="col-lg-5 col-md-6">
                                        <img src={ite9} alt='' style={{width: '100%', height: "400px", objectFit: "cover"}} />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-7 col-sm-12">
                            <div className="m-carousel-1 m-l100">
                                <OwlCarousel className="owl-carousel home-carousel-1 owl-btn-vertical-center" {...options}>
                                    {images.map((item, index) => (
                                        <div className="item" key={index}>
                                            <div className="ow-img wt-img-effect zoom-slow">
                                                <img src={item.default} alt="" />
                                            </div>
                                        </div>
                                    ))}

                                </OwlCarousel>
                            </div>
                        </div>
                </div>
            </>
                </div>

                <Footer />
            </>
        );
    };
};

export default Ite;